.graph-wrapper {
    position: relative;
}

.graph-controlls {
    position: absolute;
    top: calc(50% - 30px);
    display: flex;
    justify-content: space-between;
    width: 100%;
    pointer-events: none;

    button{
        pointer-events: all;
    }

    svg {
        font-size: 44px;
    }
}