$tagTriSize: 10px;

.tag {
    color: #AA3000;
    background-color: lightgrey;
    position: relative;
    margin-left: $tagTriSize;
}

.tag::before {
    content: '';
    width: 0;
    height: 0;
    border-top: ($tagTriSize - 1) solid transparent;
    border-bottom: ($tagTriSize - 1) solid transparent;
    border-right: $tagTriSize solid lightgrey;
    right: 100%;
    position: absolute;
    top: 0%;
    clear: both;
}

.graph-wrapper {
    position: relative;
}

.graph-controlls {
    position: absolute;
    top: calc(50% - 30px);
    display: flex;
    justify-content: space-between;
    width: 100%;
    pointer-events: none;

    button{
        pointer-events: all;
    }

    svg {
        font-size: 44px;
    }
}